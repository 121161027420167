import { extendTheme } from '@chakra-ui/react'

const theme = {
  colors: {
    primary: {
      superLight: '#d0d7ff',
      light: '#7986cb',
      main: '#3f51b5',
      dark: '#002884',
      white: '#e8eaf6'
    },
    secondary: {
      light: '#ff7961',
      main: '#f44336',
      dark: '#ba000d',
    },
    shopee: {
      main: '#ee4d2d'
    },
    lazada: {
      main: '#0F156D'
    },
  },
}

export default extendTheme(theme)
