exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-blog-detail-tsx-content-file-path-src-contents-blogs-7-เครื่องมือช่าง-ที่ทุกคนควรมีติดบ้านไว้-ปี-2024-index-mdx": () => import("./../../../src/templates/blog_detail.tsx?__contentFilePath=/opt/build/repo/src/contents/blogs/7-เครื่องมือช่าง-ที่ทุกคนควรมีติดบ้านไว้-ปี-2024/index.mdx" /* webpackChunkName: "component---src-templates-blog-detail-tsx-content-file-path-src-contents-blogs-7-เครื่องมือช่าง-ที่ทุกคนควรมีติดบ้านไว้-ปี-2024-index-mdx" */),
  "component---src-templates-blog-detail-tsx-content-file-path-src-contents-blogs-get-to-know-workpro-index-mdx": () => import("./../../../src/templates/blog_detail.tsx?__contentFilePath=/opt/build/repo/src/contents/blogs/get-to-know-workpro/index.mdx" /* webpackChunkName: "component---src-templates-blog-detail-tsx-content-file-path-src-contents-blogs-get-to-know-workpro-index-mdx" */),
  "component---src-templates-blog-list-tsx": () => import("./../../../src/templates/blog_list.tsx" /* webpackChunkName: "component---src-templates-blog-list-tsx" */),
  "component---src-templates-product-detail-tsx-content-file-path-src-contents-products-wp-211001-มีดพับสแตนเลส-wp-index-mdx": () => import("./../../../src/templates/product_detail.tsx?__contentFilePath=/opt/build/repo/src/contents/products/wp211001-มีดพับสแตนเลส-wp/index.mdx" /* webpackChunkName: "component---src-templates-product-detail-tsx-content-file-path-src-contents-products-wp-211001-มีดพับสแตนเลส-wp-index-mdx" */),
  "component---src-templates-product-detail-tsx-content-file-path-src-contents-products-wp-211014-มีดพับด้ามไม้-index-mdx": () => import("./../../../src/templates/product_detail.tsx?__contentFilePath=/opt/build/repo/src/contents/products/wp211014-มีดพับด้ามไม้/index.mdx" /* webpackChunkName: "component---src-templates-product-detail-tsx-content-file-path-src-contents-products-wp-211014-มีดพับด้ามไม้-index-mdx" */),
  "component---src-templates-product-detail-tsx-content-file-path-src-contents-products-wp-213002-ใบมีดอเนกประสงค์-sk-5-สำหรับงานหนัก-10-ชิ้น-index-mdx": () => import("./../../../src/templates/product_detail.tsx?__contentFilePath=/opt/build/repo/src/contents/products/wp213002-ใบมีดอเนกประสงค์-sk5-สำหรับงานหนัก-10-ชิ้น/index.mdx" /* webpackChunkName: "component---src-templates-product-detail-tsx-content-file-path-src-contents-products-wp-213002-ใบมีดอเนกประสงค์-sk-5-สำหรับงานหนัก-10-ชิ้น-index-mdx" */),
  "component---src-templates-product-detail-tsx-content-file-path-src-contents-products-wp-213005-มีดคัดเตอร์อลูมิเนียม-index-mdx": () => import("./../../../src/templates/product_detail.tsx?__contentFilePath=/opt/build/repo/src/contents/products/wp213005-มีดคัดเตอร์อลูมิเนียม/index.mdx" /* webpackChunkName: "component---src-templates-product-detail-tsx-content-file-path-src-contents-products-wp-213005-มีดคัดเตอร์อลูมิเนียม-index-mdx" */),
  "component---src-templates-product-detail-tsx-content-file-path-src-contents-products-wp-213010-มีดคัตเตอร์เซฟตี้-index-mdx": () => import("./../../../src/templates/product_detail.tsx?__contentFilePath=/opt/build/repo/src/contents/products/wp213010-มีดคัตเตอร์เซฟตี้/index.mdx" /* webpackChunkName: "component---src-templates-product-detail-tsx-content-file-path-src-contents-products-wp-213010-มีดคัตเตอร์เซฟตี้-index-mdx" */),
  "component---src-templates-product-detail-tsx-content-file-path-src-contents-products-wp-214006-กรรไกรอเนกประสงค์-250-มม-10-index-mdx": () => import("./../../../src/templates/product_detail.tsx?__contentFilePath=/opt/build/repo/src/contents/products/wp214006-กรรไกรอเนกประสงค์-250มม-10/index.mdx" /* webpackChunkName: "component---src-templates-product-detail-tsx-content-file-path-src-contents-products-wp-214006-กรรไกรอเนกประสงค์-250-มม-10-index-mdx" */),
  "component---src-templates-product-detail-tsx-content-file-path-src-contents-products-wp-221046-ไขควง-6-in-1-index-mdx": () => import("./../../../src/templates/product_detail.tsx?__contentFilePath=/opt/build/repo/src/contents/products/wp221046-ไขควง-6-in-1/index.mdx" /* webpackChunkName: "component---src-templates-product-detail-tsx-content-file-path-src-contents-products-wp-221046-ไขควง-6-in-1-index-mdx" */),
  "component---src-templates-product-detail-tsx-content-file-path-src-contents-products-wp-231081-คีมถ่างแหวนปากงอ-index-mdx": () => import("./../../../src/templates/product_detail.tsx?__contentFilePath=/opt/build/repo/src/contents/products/WP231081-คีมถ่างแหวนปากงอ/index.mdx" /* webpackChunkName: "component---src-templates-product-detail-tsx-content-file-path-src-contents-products-wp-231081-คีมถ่างแหวนปากงอ-index-mdx" */),
  "component---src-templates-product-detail-tsx-content-file-path-src-contents-products-wp-231087-250-มม-10-คีมคอม้า-v-jaw-index-mdx": () => import("./../../../src/templates/product_detail.tsx?__contentFilePath=/opt/build/repo/src/contents/products/wp231087-250มม-10-คีมคอม้า-v-jaw/index.mdx" /* webpackChunkName: "component---src-templates-product-detail-tsx-content-file-path-src-contents-products-wp-231087-250-มม-10-คีมคอม้า-v-jaw-index-mdx" */),
  "component---src-templates-product-detail-tsx-content-file-path-src-contents-products-wp-232031-แคลมป์จับชิ้นงาน-ปากกาอัดไม้-รูปตัว-f-index-mdx": () => import("./../../../src/templates/product_detail.tsx?__contentFilePath=/opt/build/repo/src/contents/products/wp232031-แคลมป์จับชิ้นงาน-ปากกาอัดไม้-รูปตัว-f/index.mdx" /* webpackChunkName: "component---src-templates-product-detail-tsx-content-file-path-src-contents-products-wp-232031-แคลมป์จับชิ้นงาน-ปากกาอัดไม้-รูปตัว-f-index-mdx" */),
  "component---src-templates-product-detail-tsx-content-file-path-src-contents-products-wp-271012-ด้ามฟรี-ด้ามก๊อกแก๊ก-หุ้มยาง-quick-release-index-mdx": () => import("./../../../src/templates/product_detail.tsx?__contentFilePath=/opt/build/repo/src/contents/products/wp271012-ด้ามฟรี-ด้ามก๊อกแก๊ก-หุ้มยาง-quick-release/index.mdx" /* webpackChunkName: "component---src-templates-product-detail-tsx-content-file-path-src-contents-products-wp-271012-ด้ามฟรี-ด้ามก๊อกแก๊ก-หุ้มยาง-quick-release-index-mdx" */),
  "component---src-templates-product-detail-tsx-content-file-path-src-contents-products-wp-281003-กระเป๋าเก็บเครื่องมือทรงปากกว้างซิปบน-12-index-mdx": () => import("./../../../src/templates/product_detail.tsx?__contentFilePath=/opt/build/repo/src/contents/products/wp281003-กระเป๋าเก็บเครื่องมือทรงปากกว้างซิปบน-12/index.mdx" /* webpackChunkName: "component---src-templates-product-detail-tsx-content-file-path-src-contents-products-wp-281003-กระเป๋าเก็บเครื่องมือทรงปากกว้างซิปบน-12-index-mdx" */),
  "component---src-templates-product-detail-tsx-content-file-path-src-contents-products-wp-281004-กระเป๋าเก็บเครื่องมือทรงปากกว้างซิปบน-14-index-mdx": () => import("./../../../src/templates/product_detail.tsx?__contentFilePath=/opt/build/repo/src/contents/products/wp281004-กระเป๋าเก็บเครื่องมือทรงปากกว้างซิปบน-14/index.mdx" /* webpackChunkName: "component---src-templates-product-detail-tsx-content-file-path-src-contents-products-wp-281004-กระเป๋าเก็บเครื่องมือทรงปากกว้างซิปบน-14-index-mdx" */),
  "component---src-templates-product-detail-tsx-content-file-path-src-contents-products-wp-281007-กระเป๋าเก็บครื่องมือซิปบน-16-index-mdx": () => import("./../../../src/templates/product_detail.tsx?__contentFilePath=/opt/build/repo/src/contents/products/wp281007-กระเป๋าเก็บครื่องมือซิปบน-16/index.mdx" /* webpackChunkName: "component---src-templates-product-detail-tsx-content-file-path-src-contents-products-wp-281007-กระเป๋าเก็บครื่องมือซิปบน-16-index-mdx" */),
  "component---src-templates-product-detail-tsx-content-file-path-src-contents-products-wp-281011-กระเป๋าเครื่องมือทรงปากกว้าง-320-มม-13-index-mdx": () => import("./../../../src/templates/product_detail.tsx?__contentFilePath=/opt/build/repo/src/contents/products/wp281011-กระเป๋าเครื่องมือทรงปากกว้าง-320มม-13/index.mdx" /* webpackChunkName: "component---src-templates-product-detail-tsx-content-file-path-src-contents-products-wp-281011-กระเป๋าเครื่องมือทรงปากกว้าง-320-มม-13-index-mdx" */),
  "component---src-templates-product-detail-tsx-content-file-path-src-contents-products-wp-291007-คีมปอกสายไฟอัตโนมัติ-3-in-1-index-mdx": () => import("./../../../src/templates/product_detail.tsx?__contentFilePath=/opt/build/repo/src/contents/products/wp291007-คีมปอกสายไฟอัตโนมัติ-3-in-1/index.mdx" /* webpackChunkName: "component---src-templates-product-detail-tsx-content-file-path-src-contents-products-wp-291007-คีมปอกสายไฟอัตโนมัติ-3-in-1-index-mdx" */),
  "component---src-templates-product-detail-tsx-content-file-path-src-contents-products-wp-291010-ที่ปอกสายไฟอเนกประสงค์-index-mdx": () => import("./../../../src/templates/product_detail.tsx?__contentFilePath=/opt/build/repo/src/contents/products/wp291010-ที่ปอกสายไฟอเนกประสงค์/index.mdx" /* webpackChunkName: "component---src-templates-product-detail-tsx-content-file-path-src-contents-products-wp-291010-ที่ปอกสายไฟอเนกประสงค์-index-mdx" */),
  "component---src-templates-product-detail-tsx-content-file-path-src-contents-products-wp-294003-กรรไกรตัดสายไฟอเนกประสงค์-index-mdx": () => import("./../../../src/templates/product_detail.tsx?__contentFilePath=/opt/build/repo/src/contents/products/wp294003-กรรไกรตัดสายไฟอเนกประสงค์/index.mdx" /* webpackChunkName: "component---src-templates-product-detail-tsx-content-file-path-src-contents-products-wp-294003-กรรไกรตัดสายไฟอเนกประสงค์-index-mdx" */),
  "component---src-templates-product-detail-tsx-content-file-path-src-contents-products-wp-329025-ถาดโคลนสแตนเลส-index-mdx": () => import("./../../../src/templates/product_detail.tsx?__contentFilePath=/opt/build/repo/src/contents/products/wp329025-ถาดโคลนสแตนเลส/index.mdx" /* webpackChunkName: "component---src-templates-product-detail-tsx-content-file-path-src-contents-products-wp-329025-ถาดโคลนสแตนเลส-index-mdx" */),
  "component---src-templates-product-detail-tsx-content-file-path-src-contents-products-wp-349001-ไขควงวัดไฟ-vde-3-x-70-mm-index-mdx": () => import("./../../../src/templates/product_detail.tsx?__contentFilePath=/opt/build/repo/src/contents/products/wp349001-ไขควงวัดไฟ-vde-3x70mm/index.mdx" /* webpackChunkName: "component---src-templates-product-detail-tsx-content-file-path-src-contents-products-wp-349001-ไขควงวัดไฟ-vde-3-x-70-mm-index-mdx" */),
  "component---src-templates-product-detail-tsx-content-file-path-src-contents-products-wp-353000-ไฟฉายคาดหัว-led-index-mdx": () => import("./../../../src/templates/product_detail.tsx?__contentFilePath=/opt/build/repo/src/contents/products/wp353000-ไฟฉายคาดหัว-led/index.mdx" /* webpackChunkName: "component---src-templates-product-detail-tsx-content-file-path-src-contents-products-wp-353000-ไฟฉายคาดหัว-led-index-mdx" */),
  "component---src-templates-product-detail-tsx-content-file-path-src-contents-products-wp-353001-ไฟฉายคาดหัว-led-index-mdx": () => import("./../../../src/templates/product_detail.tsx?__contentFilePath=/opt/build/repo/src/contents/products/wp353001-ไฟฉายคาดหัว-led/index.mdx" /* webpackChunkName: "component---src-templates-product-detail-tsx-content-file-path-src-contents-products-wp-353001-ไฟฉายคาดหัว-led-index-mdx" */),
  "component---src-templates-product-detail-tsx-content-file-path-src-contents-products-wp-353002-ไฟฉายคาดหัวแบบชาร์จได้-index-mdx": () => import("./../../../src/templates/product_detail.tsx?__contentFilePath=/opt/build/repo/src/contents/products/wp353002-ไฟฉายคาดหัวแบบชาร์จได้/index.mdx" /* webpackChunkName: "component---src-templates-product-detail-tsx-content-file-path-src-contents-products-wp-353002-ไฟฉายคาดหัวแบบชาร์จได้-index-mdx" */),
  "component---src-templates-product-detail-tsx-content-file-path-src-contents-products-wp-371001-workpro-ถุงมือช่าง-l-index-mdx": () => import("./../../../src/templates/product_detail.tsx?__contentFilePath=/opt/build/repo/src/contents/products/wp371001-workpro-ถุงมือช่าง-l/index.mdx" /* webpackChunkName: "component---src-templates-product-detail-tsx-content-file-path-src-contents-products-wp-371001-workpro-ถุงมือช่าง-l-index-mdx" */),
  "component---src-templates-product-detail-tsx-content-file-path-src-contents-products-wp-372000-แว่นตาเซฟตี้-ce-index-mdx": () => import("./../../../src/templates/product_detail.tsx?__contentFilePath=/opt/build/repo/src/contents/products/wp372000-แว่นตาเซฟตี้-ce/index.mdx" /* webpackChunkName: "component---src-templates-product-detail-tsx-content-file-path-src-contents-products-wp-372000-แว่นตาเซฟตี้-ce-index-mdx" */),
  "component---src-templates-product-list-tsx": () => import("./../../../src/templates/product_list.tsx" /* webpackChunkName: "component---src-templates-product-list-tsx" */)
}

